import { serviceRequest } from "@common/base.service";

const LOCAL_HOST = "http://localhost:8080";

const STUDIO_SERVER =
  process.env.NODE_ENV === "development"
    ? LOCAL_HOST
    : "https://skynoveau-studio-server.vercel.app";

const getUrl = (path, endPoint = STUDIO_SERVER) => {
  return `${endPoint}${path}`;
};

// ---------- user ----------
export const userService = {
  login: ({ email, password }) => {
    let path = `/user/login`;
    return serviceRequest({
      method: "post",
      url: getUrl(path),
      payload: {
        email,
        password,
      },
    });
  },
};
