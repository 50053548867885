import { create } from "zustand";

const STORAGE_KEY = "skynoveauStudio";

const initialState = {
  accountInfo: JSON.parse(localStorage.getItem(STORAGE_KEY)) || null,
  pageLoading: false,
};

export const useLoginStore = create((setState) => ({
  ...initialState,
  setPageLoading: (data) => {
    setState((state) => {
      return { pageLoading: data };
    });
  },

  // ---------- auth ----------
  setAccountInfo: (data) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    setState((state) => {
      return { accountInfo: data };
    });
  },
  setLogout: () => {
    setState((state) => {
      localStorage.removeItem(STORAGE_KEY);
      return { accountInfo: null };
    });
  },
}));
