import React from "react";
import { Helmet } from "react-helmet";

import { useScrollToTop } from "@common/hooks";

import Logo from "@assets/logo/studio-logo/logo.png";

import styles from "./web.module.css";
import { Link } from "react-router-dom";

const WHATSAPP_NUMBER = "9360375911";
const WHATSAPP_MESSAGE =
  "Hi there! I would like to know more about your services.";

export default function NavbarWeb() {
  useScrollToTop();

  return (
    <React.Fragment>
      <div className={`${styles.webNavbar}`}>
        <div className={`wrapper ${styles.headerWrapper}`}>
          <div className={`container ${styles.headerContainer}`}>
            <Link to="/explore" className={`${styles.logoContainer}`}>
              <img src={Logo} alt="logo" />
              <p className={`text-26 weight-400 ${styles.companyName}`}>
                Skynoveau Studio
              </p>
            </Link>
            <div className={`${styles.menuContainer}`}>
              <Link
                to="/explore"
                className={`text-18 weight-300 ${styles.menuItem}`}
              >
                Home
              </Link>
              <a
                href={`https://wa.me/${WHATSAPP_NUMBER}?text=${encodeURIComponent(
                  WHATSAPP_MESSAGE
                )}`}
                target="_blank"
                className={`text-18 weight-300 ${styles.menuItem}`}
                rel="noreferrer"
              >
                Contact
              </a>
              <Link
                to="/explore/login"
                className={`text-16 weight-300 ${styles.lasMenuItem}`}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
