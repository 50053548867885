import { Loader } from "@components/loader/basic/fullscreen";
import React, { Suspense, useRef } from "react";

export const SuspenseLazyLoader = (importFunc, delay = 1000, loader) => {
  return function WithSuspense(props) {
    const ref = useRef({ loading: true });

    const LazyComponent = React.lazy(async () => {
      const startTime = Date.now();
      const module = await importFunc();
      const endTime = Date.now();
      const loadTime = endTime - startTime;

      const remainingTime = loadTime < delay ? delay - loadTime : 0;

      let extendDelay =
        remainingTime <= 50 ? delay - remainingTime : remainingTime;

      console.log("extend delay ", extendDelay);
      setTimeout(() => {
        ref.current.loading = false;
      }, extendDelay - 10);

      await new Promise((resolve) => setTimeout(resolve, extendDelay));

      console.info(
        `lazy component loaded ${loadTime}ms - remain ${remainingTime}ms at ${delay}ms`
      );
      ref.current.loading = true;
      console.log("-----------------------------------------");

      return module;
    });

    console.log("loader state ", ref.current.loading);

    return (
      <Suspense
        fallback={
          loader ? (
            loader(ref.current.loading)
          ) : (
            <Loader loading={ref.current.loading} />
          )
        }
        ref={ref}
      >
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};
