import { NavbarMobile } from "./mobile";

import styles from "./style.module.css";
import NavbarWeb from "./web";

export const Navbar = () => {
  return (
    <>
      <div className={`${styles.mobileNavbar}`}>
        <NavbarMobile />
      </div>
      <div className={`${styles.webNavbar}`}>
        <NavbarWeb />
      </div>
    </>
  );
};
