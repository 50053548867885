import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useScrollToTop } from "@common/hooks";
import { Login } from "@components/login";
import { Toast } from "@components/form/toast/compact";
import { TOAST_TYPES } from "@components/form/toast/constant";
import { userService } from "../service/service";
import { useLoginStore } from "../store/login.store";

import styles from "./login.module.css";
import { Navbar } from "explore/navbar";

export default function LoginPage() {
  const { showMessage, toastContext } = Toast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useScrollToTop();

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = {
      email: values.email,
      password: values.password,
    };

    setTimeout(() => {
      if (
        payload.email === "dhanikabysanskriti@gmail.com" &&
        payload.password === "Dhanika@1st"
      ) {
        useLoginStore.getState().setAccountInfo({
          projectId: "dhanika-swq234",
        });
        setLoading(false);
        showMessage({
          message: "Login successful",
          type: TOAST_TYPES.SUCCESS,
        });
        navigate("/");
        return;
      } else {
        setLoading(false);
        if (payload.email !== "dhanikabysanskriti@gmail.com") {
          showMessage({
            message: "Invalid user",
            type: TOAST_TYPES.ERROR,
          });
        }
        if (payload.password !== "Dhanika") {
          showMessage({
            message: "Invalid password",
            type: TOAST_TYPES.ERROR,
          });
        }
      }
    }, 500);

    // userService
    //   .login({ ...payload })
    //   .then((result) => {
    //     useLoginStore.getState().setAccountInfo({
    //       projectId: result._id,
    //     });
    //     setLoading(false);
    //     showMessage({
    //       message: "Login successful",
    //       type: TOAST_TYPES.SUCCESS,
    //     });
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     showMessage({ message: error.message });
    //     setLoading(false);
    //   });
  };

  return (
    <>
      <Helmet>
        <title>Login - Secure Access to Your E-Commerce Dashboard</title>
        <meta
          name="description"
          content="Log in to access your eCommerce account and manage product data securely with Skynoveau Studio."
        />
        <meta
          name="keywords"
          content="login, access, sign in, login to your eCommerce account, sign in to manage products, secure login for eCommerce management, login to your eCommerce account to access product management features, secure sign-in page for managing eCommerce product data"
        />
      </Helmet>

      {toastContext}

      <Navbar />

      <Login
        onSubmit={onSubmit}
        title="Studio Login"
        allowLink={false}
        loading={loading}
        image={
          "https://skynoveau.in/static/media/logo.6fc261ae8cad64cdc848.png"
        }
        className={styles.loginWrapper}
      />
    </>
  );
}
