import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

export const serviceRequest = async ({ method, url, payload = {} }) => {
  try {
    let response;

    switch (method) {
      case "get":
        response = await axios.get(url, payload);
        break;
      case "post":
        response = await axios.post(url, payload);
        break;
      case "put":
        response = await axios.put(url, payload);
        break;
      case "delete":
        response = await axios.delete(url);
        break;
      default:
        return null;
    }
    return response.data;
  } catch (error) {
    throw new Error(
      error?.response?.data?.error?.message || error?.response?.data?.message
    );
  }
};

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = (queryList) => {
    queryClient.invalidateQueries({ queryKey: queryList });
  };

  return {
    invalidateQuery,
  };
};
